import styled, { css } from "styled-components"

export const ToggleContainer = styled.button`
  display: flex;
  align-items: center;
  border: 0;
  outline: 0;
  box-shadow: none;
  background: transparent;

  color: #fff !important;
  font-size: 1.5rem;
`

export const SwitchContainer = styled.div`
  height: 36px;
  width: 61px;
  background: ${({ theme: { primary } }) => primary};
  border-radius: 20px;
  margin: 0 10px;
  position: relative;
`

export const Circle = styled.div`
  position: absolute;
  width: 26px;
  height: 26px;
  top: 5px;
  transition: all 300ms linear;
  ${({ isDark }) =>
    isDark
      ? css`
          left: 5px;
        `
      : css`
          left: 30px;
        `};
  background: #e7e7e8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
`
