import React, { useState, useEffect } from "react"
import { IoIosSunny, IoIosMoon } from "react-icons/io"

import {
  useGlobalDispatchContext,
  useGlobalStateContext,
} from "./../context/globalContext"

import {
  ToggleContainer,
  SwitchContainer,
  Circle,
} from "./../styles/themeToggle"

// helpers
import { toggleTheme } from "./../helpers"

// light
// dark

const ThemeToggle = () => {
  const dispatch = useGlobalDispatchContext()
  const { currentTheme } = useGlobalStateContext()

  const [theme, setTheme] = useState(currentTheme)

  useEffect(() => {
    setTheme(currentTheme)
  }, [currentTheme])

  const _toggleTheme = () => {
    toggleTheme(dispatch, currentTheme)
  }

  return (
    <div className="toggleTheme">
      <ToggleContainer aria-label="Toggle Theme" onClick={_toggleTheme}>
        <IoIosSunny />
        <SwitchContainer>
          <Circle isDark={theme === "dark" ? false : true} />
        </SwitchContainer>
        <IoIosMoon />
      </ToggleContainer>
    </div>
  )
}

export default ThemeToggle
