import React, { useState } from "react"
import { useStaticQuery, graphql, Link, navigate } from "gatsby"
import Img from "gatsby-image"
import { Formik } from "formik"
import * as Yup from "yup"
import emailjs from "emailjs-com"

import { Container, Button } from "./../styles/GlobalStyles"
import {
  Footer as FooterStyle,
  FirstRow,
  SecondRow,
  ThirdRow,
  Logo,
  InputWrapper,
  Input,
  SendIcon,
  Instagram,
  Twitter,
  LinkedIn,
  Facebook,
  ErrorMessage,
  StatusMessage,
} from "./../styles/footer"

// component
import ThemeToggle from "./ThemeToggle"

const NewsLetterSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email Required"),
})

const Footer = () => {
  const [message, setMessage] = useState("")
  const [mailStatus, setMailStatus] = useState()
  const [isSending, setIsSending] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      mellowframes: file(relativePath: { eq: "mellowframes.png" }) {
        childImageSharp {
          fluid(maxHeight: 35, maxWidth: 180, fit: INSIDE) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const onSubmitNewsLetter = values => {
    const template_params = {
      subject: "Newsletter Request",
      mailer_id: values.email,
    }

    const service_id = "gmail"
    const template_id = "template_fyOBru9Q"
    setIsSending(true)
    emailjs
      .send(
        service_id,
        template_id,
        template_params,
        "user_57uUz67osBQ62JEiYCAls"
      )
      .then(
        response => {
          setMailStatus("Mail sent successfully!")
          setTimeout(() => setMailStatus(""), 5000)
          console.log("SUCCESS!", response.status, response.text)
        },
        error => {
          setMailStatus("Mail cannot be sent.")
          setTimeout(() => setMailStatus(""), 5000)
          console.log("FAILED...", error)
        }
      )
      .finally(() => setIsSending(false))
  }

  return (
    <Container className="footerContainer">
      <FooterStyle>
        <FirstRow>
          <h1>Have an idea?</h1>
          <div className="IdeaContainer">
            <p>
              Challenge us. We want to work with you to create the really cool
              stuff.
            </p>
            <Button
              aria-label="Lets talk"
              onClick={() => {
                setTimeout(() => {
                  navigate("/contact")
                }, 300)
              }}
            >
              Let's Talk
            </Button>
          </div>
        </FirstRow>
        <div className="seperator" />
        <SecondRow>
          <div className="aboutCompany">
            <div className="rowContainer">
              <Logo>
                <Img
                  fluid={data.mellowframes.childImageSharp.fluid}
                  imgStyle={{
                    objectFit: "contain",
                    height: "100%",
                    width: "100%",
                  }}
                />
              </Logo>
              <ThemeToggle />
            </div>
            {/* <p>
              No 17, Ranga Garden,
              <br /> 3rd cross East,
              <br /> Coimbatore - 641031.
            </p> */}
            <p>
              We are the digital nomads.
              <br />
              info@mellowframes.com
              <br />
              is our virtual address
            </p>
          </div>
          <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/works">Our works</Link>
              </li>
              <li>
                <Link to="/careers">Careers</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li style={{ visibility: "hidden" }}>v</li>
            </ul>
          </nav>
          <div className="newsletter">
            <label htmlFor="email">Get our stuff in your mail.</label>
            <InputWrapper>
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={NewsLetterSchema}
                onSubmit={onSubmitNewsLetter}
              >
                {({ errors, touched, handleSubmit, handleChange, values }) => (
                  <>
                    <Input
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      aria-label="Email"
                    />
                    {isSending ? (
                      <p>Sending mail...</p>
                    ) : (
                      <SendIcon onClick={handleSubmit} />
                    )}

                    {errors.email && touched.email
                      ? setMessage(errors.email)
                      : null}
                  </>
                )}
              </Formik>
            </InputWrapper>
            {message ? <ErrorMessage>{message}</ErrorMessage> : null}
            <StatusMessage>{mailStatus}</StatusMessage>
          </div>
        </SecondRow>
        <div className="third-seperator" />
        <ThirdRow>
          <div className="copyright">
            <p>Copyright © 2020</p>
          </div>
          <ThemeToggle />
          <ul className="social">
            <a
              href="https://dribbble.com/mellowframes"
              aria-label="Instagram"
              target="_blank"
            >
              <li>
                <Instagram />
              </li>
            </a>
            <a
              href="https://dribbble.com/mellowframes"
              aria-label="Instagram"
              target="_blank"
            >
              <li>
                <LinkedIn />
              </li>
            </a>
            <a
              href="https://dribbble.com/mellowframes"
              aria-label="Instagram"
              target="_blank"
            >
              <li>
                <Facebook />
              </li>
            </a>
            <a
              href="https://dribbble.com/mellowframes"
              aria-label="Instagram"
              target="_blank"
            >
              {" "}
              <li>
                <Twitter />
              </li>
            </a>
          </ul>
        </ThirdRow>
      </FooterStyle>
    </Container>
  )
}

export default Footer
