import styled from "styled-components"

export const Clients = styled.div`
  min-height: 5.5rem;
  width: 100%;
  background: ${({ theme: { clientsBg } }) => clientsBg};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const Client = styled.div`
  width: 20%;
  min-width: 200px;
  height: 5.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    width: 200px;
  }
`

export default {
  clientImg: {
    height: "100%",
    objectFit: "contain",
  },
}
