import styled from "styled-components"
import {
  IoIosPaperPlane,
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoFacebook,
  IoLogoTwitter,
} from "react-icons/io"

export const Footer = styled.div`
  height: auto;
  width: 100%;
  background: black;
  color: #e7e7e8;

  margin-top: 125px;
  margin-bottom: 72px;
  border-radius: 28px;
  padding: 105px 85px 60px;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 150px auto 150px auto 50px;

  .toggleTheme {
    justify-self: center;
  }

  .seperator {
    width: 100%;
    height: 1px;
    background: #9fa9ae;
    margin-top: 75px;
    margin-bottom: 80px;
    opacity: 0.2;
  }
  .third-seperator {
    width: 100%;
    height: 70px;
  }

  @media (max-width: 599px) {
    grid-template-rows: auto auto auto auto auto;

    padding: 32px 20px 66px;
    margin-bottom: 0;
    border-radius: 0;

    .seperator {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    .third-seperator {
      height: 40px;
    }
  }

  @media (min-width: 600px) and (max-width: 899px) {
    grid-template-rows: auto auto auto auto auto;
    padding: 65px 35px 40px;

    .seperator {
      margin-top: 55px;
      margin-bottom: 55px;
    }
    .third-seperator {
      height: 50px;
    }
  }

  @media (min-width: 900px) and (max-width: 1199px) {
    padding: 75px 50px 50px;
  }
`

export const FirstRow = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;

  h1 {
    font-size: 2.81rem;
    text-align: center;
  }
  .IdeaContainer {
    p {
      font-size: 1.25rem;
      line-height: 30px;
      max-width: 80%;
      margin-bottom: 32px;
    }
  }

  @media (max-width: 599px) {
    grid-template-columns: 100%;
    .IdeaContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        max-width: 100%;
        text-align: center;
        margin-bottom: 25px;
        margin-top: 16px;
      }
    }
  }

  @media (min-width: 900px) and (max-width: 1200px) {
    .IdeaContainer {
      p {
        max-width: 100%;
      }
    }
  }
`
export const SecondRow = styled.div`
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;

  .rowContainer {
    margin-bottom: 1rem;
  }

  .aboutCompany {
    .toggleTheme {
      display: none;
    }
  }

  p {
    line-height: 25px;
  }

  nav {
    justify-self: center;
    display: grid;
    grid-template-columns: 130px 130px;
    column-gap: 50px;
    li {
      margin-bottom: 30px;

      a {
        font-size: 1.25rem;
        color: #e7e7e8;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .newsletter {
    justify-self: end;
    label {
      display: block;
      margin-bottom: 25px;
    }
  }

  @media (max-width: 599px) {
    grid-template-columns: 100%;
    nav {
      display: none;
    }
    .aboutCompany {
      .toggleTheme {
        display: block;
      }
    }
    .rowContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .newsletter {
      margin-top: 32px;
      justify-self: auto;
      label {
        font-size: 1.8rem;
        margin-bottom: 20px;
      }
    }
  }

  @media (min-width: 600px) and (max-width: 970px) {
    grid-template-columns: 50% 50%;
    nav {
      column-gap: 20px;
    }
    .newsletter {
      justify-self: start;
      margin-top: 50px;
    }
  }

  @media (min-width: 900px) and (max-width: 1200px) {
    nav {
      column-gap: 20px;
    }
  }
`
export const ThirdRow = styled.div`
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  align-items: center;

  .copyright {
  }
  .toggleTheme {
    justify-self: center;
  }
  .social {
    justify-self: end;
    li {
      float: left;
      padding: 0 5px;
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 599px) {
    grid-template-columns: 100%;
    .toggleTheme {
      display: none;
    }
    .copyright {
      grid-row: 2;
    }
    .social {
      justify-self: center;
      grid-row: 1;
      margin-bottom: 50px;
    }
  }
`

export const Logo = styled.div`
  height: 45px;
  width: 240px;

  @media (max-width: 599px) {
    height: 33px;
    width: 173px;
  }

  @media (min-width: 900px) and (max-width: 1200px) {
    height: 42px;
    width: 230px;
  }
`

export const InputWrapper = styled.div`
  position: relative;
`

export const Input = styled.input.attrs({
  type: "email",
})`
  height: 50px;
  width: 100%;
  border: 0;
  background: transparent;
  border: 1px solid #9fa9ae;
  border-radius: 5px;
  box-sizing: border-box;
  padding-right: 10px;
  box-shadow: none;
  padding: 15px;
  padding-right: 40px;
  box-sizing: border-box;
  color: #ffffff;

  &:focus {
    outline: none;
    border: 1px solid ${({ theme: { primary } }) => primary};
  }
  @media (max-width: 599px) {
    max-width: 100%;
  }
`

export const SendIcon = styled(IoIosPaperPlane)`
  font-size: 1.5rem;
  width: 35px;
  height: 50px;
  position: absolute;
  right: 5px;
  color: ${({ theme: { primary } }) => primary};

  @media (max-width: 599px) {
    font-size: 2.5rem;
  }
`

export const Instagram = styled(IoLogoInstagram)`
  font-size: 1.3rem;
  color: #e7e7e8;
  margin-right: 10px;
`
export const LinkedIn = styled(IoLogoLinkedin)`
  font-size: 1.3rem;
  color: #e7e7e8;
  margin-right: 10px;
`
export const Facebook = styled(IoLogoFacebook)`
  font-size: 1.3rem;
  color: #e7e7e8;
  margin-right: 10px;
`
export const Twitter = styled(IoLogoTwitter)`
  font-size: 1.3rem;
  color: #e7e7e8;
`

export const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
  margin-bottom: 20px;
`

export const StatusMessage = styled.div`
  color: green;
  margin-top: 10px;
  margin-bottom: 10px;
`
