import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import styles, { Clients as ClientsStyle, Client } from "./../styles/clients"

const clients = ["client1", "client2", "client3", "client4", "client5"]

const Clients = () => {
  const data = useStaticQuery(graphql`
    query {
      client1: file(relativePath: { eq: "client-1.png" }) {
        childImageSharp {
          fixed(height: 90, width: 110, fit: INSIDE) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      client2: file(relativePath: { eq: "client-2.png" }) {
        childImageSharp {
          fixed(height: 100, width: 125, fit: INSIDE) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      client3: file(relativePath: { eq: "client-3.png" }) {
        childImageSharp {
          fixed(height: 90, width: 90, fit: INSIDE) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      client4: file(relativePath: { eq: "client-4.png" }) {
        childImageSharp {
          fixed(height: 130, width: 150, fit: INSIDE) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      client5: file(relativePath: { eq: "client-5.png" }) {
        childImageSharp {
          fixed(height: 50, width: 110, fit: INSIDE) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <ClientsStyle>
      {clients.map((_client, index) => (
        <Client key={index}>
          <Img
            fixed={data[_client].childImageSharp.fixed}
            imgStyle={styles.clientImg}
          />
        </Client>
      ))}
    </ClientsStyle>
  )
}

export default Clients
